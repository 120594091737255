import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
var app = createApp(App)
app.use(store).use(router).mount('#app')
const http = axios.create({
    baseURL: "http://api.6hbd.org/",
    //baseURL: "http://api.mjc.la/",
    //baseURL: "https://localhost:6001/",
    headers: {
        "Content-Type": "application/x-www-form-urlencoded"
    },
    //headers: { "Content-Type": "application/json" },
    //   headers: { "Access-Control-Allow-Origin": "*" },
});
// http.defaults.headers.post["Content-Type"] =
//   "application/x-www-form-urlencoded";

app.config.globalProperties.axios = http;