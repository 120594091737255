<template>
  <div class="header-box">
    <div class="block centent-box">
      <div class="logo-box">
        <!-- <img src="../assets/icon_1.png" alt=""> -->
        {{titles[0]}}
      </div>
      <div class="right-box">

        <div class="item-box">
          <a href="https://49lh11.com" target="_blank">
            <div class="icon-box">
              <img src="../assets/49.png" alt="">
            </div>
            <p>网址大全</p>
          </a>
        </div>

        <div class="item-box">
          <a href="">
            <div class="icon-box">
              <img src="../assets/icon_2.png" alt="">
            </div>
            <p>{{titles[1]}}</p>
          </a>
        </div>
        <div class="item-box">
          <a href="">
            <div class="icon-box">
              <img src="../assets/icon_3.png" alt="">
            </div>
            <p>{{titles[2]}}</p>
          </a>
        </div>

        <div class="item-box">
          <a href="">
            <div class="icon-box">
              <img src="../assets/icon_5.png" alt="">
            </div>
            <p>{{titles[3]}}</p>
          </a>
        </div>
        <!-- <div class="item-box">
          <a href="">
            <div class="icon-box">
              <img src="../assets/icon_6.png" alt="">
            </div>
            <p>{{titles[4]}}</p>
          </a>
        </div> -->
        <div class="item-box">
          <a href="">
            <div class="icon-box">
              <img src="../assets/icon_7.png" alt="">
            </div>
            <p>{{titles[5]}}</p>
          </a>
        </div>

        
      
      </div>
    </div>
  </div>
</template>
    
<script setup>
import { ref } from 'vue'
// import { reactive } from 'vue';
// import Decrypt from "../decrypt.js"    
import CryptoJS from "crypto-js";
function Decrypt(str) {
    var KEY = "12345678900000001234567890000000"; //32位
    var IV = "1234567890000000"; //16位
    var key = CryptoJS.enc.Utf8.parse(KEY);
    var iv = CryptoJS.enc.Utf8.parse(IV);
    var encryptedHexStr = CryptoJS.enc.Hex.parse(str);
    var srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
    var decrypt = CryptoJS.AES.decrypt(srcs, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });
    var decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    return decryptedStr.toString();
}
let tmp = Decrypt("da87a510e2930843756f649ecdce8b605fae38e78d4da7ac81cb6761c07ce8e1384088a2f9f460f7eed721069aeb09d65babbd211753e22abfc7e8309310dd1dbda6d3d80eb380c072a63765fba0a720");

let cmenu = ref(JSON.parse(tmp));
let tmp2 = Decrypt("da87a510e2930843756f649ecdce8b605fae38e78d4da7ac81cb6761c07ce8e1384088a2f9f460f7eed721069aeb09d65babbd211753e22abfc7e8309310dd1dbda6d3d80eb380c072a63765fba0a720");

let titles = ref(JSON.parse(tmp2));
document.title = titles.value[0];
    
   console.log(cmenu)
    
   
    
</script>
    
<style  scoped>
.header-box {
  background-color: #fa4e4e;
  height: 100px;
}
.header-box .centent-box {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-box .logo-box {
  font-size: 32px;
  color: #fff;
  font-weight: 700;
}
.header-box .logo-box img {
  height: 65px;
  margin-right: 20px;
}
.header-box .right-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-box .right-box .item-box {
  text-align: center;
  margin-right: 60px;
}
.header-box .right-box .red {
  text-align: center;
  margin-right: 60px;
  background-image: url("../assets/icon_13.png");
      font-size: 17px;
    font-weight: 700;
}
.header-box .right-box .blue {
  text-align: center;
  margin-right: 60px;
  background-image: url("../assets/icon_12.png");
      font-size: 17px;
    font-weight: 700;
}
.header-box .right-box .green {
  text-align: center;
  margin-right: 60px;
  background-image: url("../assets/icon_11.png");
      font-size: 17px;
    font-weight: 700;
}
.header-box .right-box .item-box a {
  display: block;
}
.header-box .right-box .item-box a .icon-box {
  height: 44px;
}

.header-box .right-box .item-box a .icon-box img {
  width: 45.6px;
  height: 34.4px;
  vertical-align: top;
  transform: scale(1.0);
}
.header-box .right-box .item-box a p {
  color: #fff;
}
.header-box .right-box .item-box:nth-child(4) a .icon-box {
  position: relative;
  top: -4px;
}
.header-box .right-box .item-box:nth-child(5) {
  margin-right: 0;
}
.header-box .right-box .item-box:nth-child(5) a .icon-box {
  position: relative;
  top: -3px;
}
.header-box .right-box .item-box:nth-child(5) a .icon-box img {
  transform: scale(1.0);
}

</style>
    